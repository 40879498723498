import { css } from '@emotion/react';

export const desktopMaxWidth = 1200;
export const desktopPadding = 32;
export const mobilePadding = 16;

const global = css`
  * {
    overscroll-behavior: none;
  }

  html,
  body,
  #root {
    /* framer-motion 모바일일 때 뷰에 보이지 않아도 무조건 동작하는 문제 해결 */
    //overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
`;

export default global;
