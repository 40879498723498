import { css } from '@emotion/react';

const cdnUrl = (url: string) => {
  return `${process.env.GATSBY_CDN_URL ?? ''}/font${url}`;
};
const fontFamilies = css`
  @font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    src: local('Pretendard Black'), url(${cdnUrl('/Pretendard-Black.woff2')}) format('woff2'),
      url(${cdnUrl('/Pretendard-Black.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    src: local('Pretendard ExtraBold'), url(${cdnUrl('/Pretendard-ExtraBold.woff2')}) format('woff2'),
      url(${cdnUrl('/Pretendard-ExtraBold.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    src: local('Pretendard Bold'), url(${cdnUrl('/Pretendard-Bold.woff2')}) format('woff2'),
      url(${cdnUrl('/Pretendard-Bold.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    src: local('Pretendard SemiBold'), url(${cdnUrl('/Pretendard-SemiBold.woff2')}) format('woff2'),
      url(${cdnUrl('/Pretendard-SemiBold.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    src: local('Pretendard Medium'), url(${cdnUrl('/Pretendard-Medium.woff2')}) format('woff2'),
      url(${cdnUrl('/Pretendard-Medium.woff')}) format('woff');
  }

  @font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    src: local('Pretendard Regular'), url(${cdnUrl('/Pretendard-Regular.woff2')}) format('woff2'),
      url(${cdnUrl('/Pretendard-Regular.woff')}) format('woff');
  }

  @font-face {
    font-family: 'D2 coding';
    font-style: normal;
    font-weight: 400;
    src: local('D2Coding'), url('#{$cdn_link}/D2Coding.woff2') format('woff2'),
      url(${cdnUrl('/D2Coding.woff')}) format('woff'),
      url(${cdnUrl('/D2Coding.ttf')}) format('truetype');
  }

  @font-face {
    font-family: 'NanumMyeongjoExtraBold';
    font-weight: 800;
    src: local('NanumMyeongjoExtraBold'),
      url(${cdnUrl('/NanumMyeongjoExtraBold.woff2')}) format('woff2'),
      url(${cdnUrl('/NanumMyeongjoExtraBold.woff')}) format('woff'),
      url(${cdnUrl('/NanumMyeongjoExtraBold.ttf')}) format('truetype');
  }
`;

export default fontFamilies;
