import { css } from '@emotion/react';
import { Box, type MantineTheme, desktopFirstMediaQuery, useTheme } from '@inflearn/ds-react';
import { GATSBY_ANT_MAN_URL, GATSBY_CDN_URL } from '../../../../utils/env';

const IntegratedHeader = () => {
  const theme = useTheme();
  const integratedHeaderLinkStyle = IntegratedHeaderLinkStyle(theme);
  return (
    <Box component="section" css={styleIntegratedHeader(theme)}>
      <Box
        css={{
          width: '100%',
          maxWidth: 1200
        }}>
        <Box css={styleIntegratedHeaderBody(theme)}>
          <Box component="ul" css={{ display: 'flex' }}>
            <Box css={integratedHeaderLinkStyle} component="li">
              <a href={GATSBY_ANT_MAN_URL} rel="noreferrer">
                <img
                  src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-header-gray-inflearn.svg`}
                  css={{ marginRight: '4px' }}
                />
                교육
              </a>
            </Box>
            <Box css={integratedHeaderLinkStyle} component="li">
              <a href="https://infcon.day/" className="active">
                INFCON
              </a>
            </Box>
          </Box>
          <Box component="ul" css={{ display: 'flex' }}>
            <Box css={integratedHeaderLinkStyle} component="li">
              <a
                target="_blank"
                href="https://infcon.day/2022"
                rel="noreferrer"
                css={{
                  fontWeight: 500,
                  padding: 0,
                  color: theme.colors.gray[8]
                }}>
                지난 인프콘 보기
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const IntegratedHeaderLinkStyle = (theme: MantineTheme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    ':not(:last-child):after': {
      content: "''",
      display: 'block',
      margin: '0px 8px',
      width: '1px',
      height: '12px',
      background: theme.colors.gray[4]
    },
    ':not(:first-of-child)': {
      marginLeft: '2px'
    },

    a: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '2px',
      color: theme.colors.gray[6],
      fontWeight: '500',
      lineHeight: '1.43',
      letterSpacing: '-.3px',
      fontSize: 14,
      '&.active': {
        color: theme.colors.gray[9],
        fontWeight: 700
      }
    }
  });

export default IntegratedHeader;
const styleIntegratedHeaderBody = (theme: MantineTheme) =>
  css({
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '0 32px',
    maxWidth: 1200,
    color: theme.colors.gray[7],
    [desktopFirstMediaQuery.mobile]: {
      padding: '0 16px'
    }
  });

const styleIntegratedHeader = (theme: MantineTheme) =>
  css({
    position: 'relative',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    zIndex: 30
  });
