const Scripts = () => {
  return (
    <>
      <script
        type="text/javascript"
        src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=f6ecu5kym6&callback=initMap"
      />
      <script type="text/javascript" src="https://developers.kakao.com/sdk/js/kakao.js" async />
    </>
  );
};

export default Scripts;
