import { useEffect, useRef } from 'react';
import { throttle } from 'lodash-es';
import getRandomInt from '../../../../../utils/getRandomNumber';

const MAX_CIRCLE_SIZE = 300;
const MIN_CIRCLE_SIZE = 150;
const MAX_MOBILE_CIRCLE_SIZE = 150;
const MIN_MOBILE_CIRCLE_SIZE = 80;

const Background = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasContext = useRef<CanvasRenderingContext2D | null>(null);

  useEffect(() => {
    if (canvasRef.current === null) {
      return;
    }
    const context = canvasRef.current.getContext('2d');

    if (context == null) {
      return;
    }

    canvasContext.current = context;

    const circles = new Circles(context, canvasRef.current);
    circles.animate();
    circles.resize();

    window?.addEventListener('resize', throttle(() => {
      circles.resize();
    }, 100));
  }, []);

  return (
    <canvas
      width='100%'
      height="calc(100vh - 40px)"
      ref={canvasRef}
      css={{ position: 'absolute', top: 0, width: '100%', height: '100%', zIndex: -1, filter: 'blur(100px)' }}></canvas>
  );
};

class Circle {
  private dx: number;
  private dy: number;
  private size: number;
  private x: number;
  private y: number;

  constructor (
    private readonly color: string,
    private readonly context: CanvasRenderingContext2D,
    private readonly canvas: HTMLCanvasElement
  ) {
    this.size = getRandomInt(MIN_CIRCLE_SIZE, MAX_CIRCLE_SIZE);
    this.dx = getRandomInt(1, 2);
    this.dy = getRandomInt(1, 2);

    const [x, y] = this.getRandomCoordinates();
    this.x = x;
    this.y = y;
  }

  public resize () {
    if (typeof window === 'undefined') {
      return;
    }

    if (window.innerWidth <= 768) {
      this.size = getRandomInt(MIN_MOBILE_CIRCLE_SIZE, MAX_MOBILE_CIRCLE_SIZE);
      return;
    }

    this.size = getRandomInt(MIN_CIRCLE_SIZE, MAX_CIRCLE_SIZE);
  }

  public drawCircle () {
    this.context.beginPath();
    this.context.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
    this.context.stroke();
    this.context.fillStyle = this.color;
    this.context.fill();
    this.context.closePath();
  }

  public updatePosition () {
    this.x += this.dx;
    this.y += this.dy;

    const [canvasWidth, canvasHeight] = this.getMaxCoordinates();

    // Check if the circle reaches the canvas boundaries
    if (this.x + this.size > canvasWidth || this.x - this.size < 0) {
      this.dx = -this.dx; // Reverse the horizontal direction
    }

    if (this.y + this.size > canvasHeight || this.y - this.size < 0) {
      this.dy = -this.dy; // Reverse the vertical direction
    }
  }

  private getMaxCoordinates () {
    if (typeof window === 'undefined') {
      return [0, 0];
    }

    const x = this.canvas.clientWidth ?? 0;
    const y = this.canvas.clientHeight ?? 0;

    return [x, y];
  };

  private getRandomCoordinates () {
    const [maxX, maxY] = this.getMaxCoordinates();

    // TODO: Crypto로 변경
    return [getRandomInt(50, maxX), getRandomInt(50, maxY)];
  };
}

class Circles {
  private readonly circles: Circle[] = [];
  private frameId: number = 0;

  constructor (private readonly context: CanvasRenderingContext2D, private readonly canvas: HTMLCanvasElement) {
    const colorSet = ['#85FFAB', '#75DFFF'];
    for (let i = 0; i < 4; i++) {
      this.circles.push(new Circle(colorSet[i % 2], context, canvas));
    }
  }

  public clearCanvas () {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  public animate () {
    this.clearCanvas();
    this.circles.forEach(circle => { circle.drawCircle(); });
    this.circles.forEach(circle => { circle.updatePosition(); });

    this.frameId = requestAnimationFrame(this.animate.bind(this));
  }

  public stopAnimation () {
    cancelAnimationFrame(this.frameId);
  }

  public resize () {
    this.stopAnimation();

    const parentElement = this.canvas.parentElement;
    const parentWidth = parentElement?.clientWidth ?? 0;
    const parentHeight = parentElement?.clientHeight ?? 0;

    this.canvas.width = parentWidth;
    this.canvas.height = parentHeight;
    this.canvas.setAttribute('width', parentWidth.toString());
    this.canvas.setAttribute('height', parentHeight.toString());

    this.circles.forEach(circle => { circle.resize(); });
    this.animate();
  }
}

export default Background;
