import { useEffect } from 'react';

const useInitNaverMap = (id: string) => {
  useEffect(() => {
    setTimeout(() => {
      if (typeof window.naver !== 'undefined') {
        const [lat, lng] = [37.5130712, 127.0585203];
        const map = new window.naver.maps.Map(id, {
          center: new window.naver.maps.LatLng(lat, lng),
          zoom: 16,
          scrollWheel: false
        });

        // eslint-disable-next-line no-new
        new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(lat, lng),
          map
        });
      }
    }, 500);
  }, []);
};

export default useInitNaverMap;
