import { css } from '@emotion/react';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  Box,
  Button,
  desktopFirstMediaQuery,
  Icon,
  Text,
  Title,
  useTheme
} from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import React from 'react';
import { styleSection } from '../InteractionSections';
import { commonSectionItemVariants } from '../InteractionSections/variants';

const SNS = () => {
  const theme = useTheme();
  return (
    <Box component={'section'} css={[styleSNSSection, styleSection]}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        css={styleSectionInnerWrapper}
        variants={commonSectionItemVariants()}>
        <motion.div variants={commonSectionItemVariants()}>
          <Title order={2} color={theme.colors.dark[9]} css={styleCommonSectionTitle}>
            인프콘 공식 SNS 채널
          </Title>
        </motion.div>
        <motion.div variants={commonSectionItemVariants({ delay: 0.2 })}>
          <Text
            color={theme.colors.gray[7]}
            mb={40}
            align="center"
            css={styleCommonSectionDescription}>
            인프콘 소식을 가장 먼저 만나보세요
          </Text>
        </motion.div>
        <motion.div
          css={{
            display: 'flex',
            columnGap: 16,
            [desktopFirstMediaQuery.mobile]: {
              columnGap: 8,
              width: '100%'
            }
          }}
          variants={commonSectionItemVariants({ delay: 0.4 })}>
          <Button
            component="a"
            href="https://twitter.com/InflearnConf"
            target="_blank"
            size="xl"
            radius="xl"
            leftIcon={<Icon icon={faTwitter} color="black" />}
            variant="filled"
            css={styleMonoButton}>
            트위터
          </Button>
          <Button
            component="a"
            href="https://www.facebook.com/InflearnConf"
            target="_blank"
            size="xl"
            radius="xl"
            leftIcon={<Icon icon={faFacebook} color="black" />}
            variant="filled"
            css={styleMonoButton}>
            페이스북
          </Button>
        </motion.div>
      </motion.div>
    </Box>
  );
};

const styleSNSSection = css({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '100px 32px 80px',
  backgroundColor: 'white',
  [desktopFirstMediaQuery.mobile]: {
    padding: '40px 16px 60px'
  }
});

const styleSectionInnerWrapper = css({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  maxWidth: 1200,
  borderRadius: 20,
  padding: '80px 20px',
  backgroundColor: '#E5F2FF',
  [desktopFirstMediaQuery.mobile]: {
    padding: '40px 20px'
  }
});

export const styleCommonSectionTitle = css({
  marginBottom: 16,
  fontSize: 60,
  textAlign: 'center',
  [desktopFirstMediaQuery.tablet]: {
    fontSize: 54
  },
  [desktopFirstMediaQuery.mobile]: {
    fontSize: 28
  }
});

export const styleCommonSectionDescription = css({
  fontSize: 24,
  [desktopFirstMediaQuery.mobile]: {
    fontSize: 16
  }
});

export const styleMonoButton = css({
  backgroundColor: 'white',
  color: 'black',
  ':hover': {
    color: 'white',
    backgroundColor: 'black',
    transition: '.1s ease-in',
    'svg > path': {
      fill: 'white'
    }
  },
  [desktopFirstMediaQuery.mobile]: {
    width: '100%',
    padding: 0
  }
});
export default SNS;
