import { GATSBY_ANT_MAN_URL, GATSBY_ENV } from './env';

const infcon2023CoursePage =
  GATSBY_ENV === 'production'
    ? 'https://www.inflearn.com/course/%EC%9D%B8%ED%94%84%EC%BD%98-2023'
    : 'https://www.devinflearn.com/course/temp_325584';

export const prefix = GATSBY_ENV === 'localhost' ? '' : '/conf';

export const INFCON_PATH = `${prefix}/infcon-2023`;

export const URLS = {
  // NOTE: 2023
  INFCON__ACTION: `${GATSBY_ANT_MAN_URL}/pages/infcon-coc`,
  INFCON_2023__HOME: `${prefix}/infcon-2023/`,
  INFCON_2023__SESSION: `${prefix}/infcon-2023/session/`,
  INFCON_2023__SESSION_DETAIL: (id: string | number) =>
    `${prefix}/infcon-2023/session-detail?id=${id}`,
  INFCON_2023__SESSION_DETAIL_SHARE: (id: string | number) =>
    `/infcon-2023/schedule/session-detail?id=${id}${GATSBY_ENV === 'production' ? '' : '&env=dev'}`,
  INFCON_2023__EVENT: `${prefix}/infcon-2023/info/event/`,
  INFCON_2023__STORY: `${prefix}/infcon-2023/info/story/`,
  INFCON_2023__FAQ: `${prefix}/infcon-2023/faq/`,
  INFCON_2023__MY: `${prefix}/infcon-2023/mypage/`,
  INFCON_2023__MY_NAVIGATE: '/infcon-2023/mypage/',
  INFCON_2023_EVENT_DETAIL: (slug: string) => `${GATSBY_ANT_MAN_URL}/pages/${slug}`,
  SIGNIN: `${GATSBY_ANT_MAN_URL}/user/signin`,
  INFCON_2023_COURSE_PAGE: infcon2023CoursePage,
  INFCON_2023_SHARE: (id: number, hash: string, name: string) =>
    `${prefix}/infcon-2023/share?id=${id}&hash=${hash}&name=${name}`,
  INFCON_2023_REPLAY:
    'https://www.inflearn.com/course/%EC%9D%B8%ED%94%84%EC%BD%982023-%EB%8B%A4%EC%8B%9C%EB%B3%B4%EA%B8%B0',

  // NOTE: 2024
  INFCON_2024__ACTION: 'https://www.inflearn.com/pages/infcon-2024-coc',
  INFCON_2024__HOME: `/infcon-2024/`,
  INFCON_2024__SESSION: `/infcon-2024/session/`,
  INFCON_2024__SESSION_DETAIL: (id: string | number) =>
    `${prefix}/infcon-2024/session-detail/${id}/`,
  INFCON_2024__FAQ: `/infcon-2024/faq/`,
  INFCON_2024__MYPAGE: `/infcon-2024/mypage/`,
  INFCON_2024__EVENT: `/infcon-2024/info/event/`,
  INFCON_2024_EVENT_DETAIL: (slug: string) => `${GATSBY_ANT_MAN_URL}/pages/${slug}`,
  INFCON_2024__MY: `/infcon-2024/mypage/`,
  INFCON_2024__APPLY: `https://www.inflearn.com/course/offline/%EC%9D%B8%ED%94%84%EC%BD%98-2024`,
  INFCON_2024_SHARE: `/infcon-2024/share/`
} as const;

export const API_URLS = {
  EVENT_LIST: (year: number) => `/api/infcon/pages?subType=EVENT&year=${year}`,
  STORY_LIST: (year: number) => `/api/infcon/pages?subType=STORY&year=${year}`,
  SESSION_LIST: (year: number) => `/api/infcon/pages?subType=SESSION&year=${year}`,
  PROGRAM_LIST: (year: number) => `/api/infcon/pages?subType=PARTICIPATION&year=${year}`,
  USER_SESSION: '/api/v1/session?version=2',
  SPEAKER_SESSION_DETAIL: (id: number) => `/api/infcon/pages/${id}`,
  ADD_SCHEDULE: (id: number, year: number) => `/api/infcon/pages/${id}/schedule?year=${year}`,
  DELETE_SCHEDULE: (id: number, year: number) => `/api/infcon/pages/${id}/schedule?year=${year}`,
  MY_SCHEDULE: (year?: number) => `/api/infcon/my-schedule${year ? `?year=${year}` : ''}`,
  SHARE_MY_SCHEDULE: (year: number) => `/api/infcon/my-schedule/link?year=${year}`,
  USER_SCHEDULE: (userId: number, hash: string, year: number) =>
    `/api/infcon/users/${userId}/${hash}/schedule?year=${year}`,
  USER_V2_SESSION: '/api/v2/session',
  V1_ACCOUNT_PROFILE: '/api/v1/account/profile',
  SURVEY_ANSWER: (userId?: number) =>
    `/api/infcon/survey-answer${userId ? `?user_id=${userId}` : ''}`,
  APPLICANT_STATUS: '/api/infcon/applicant-status',
  RALLIT_HUB: (accountId: number) => `/api/v1/resume-hub/summary?accountId=${accountId}`
};
