export const textTransitionInitialOptions = {
  opacity: 0,
  y: 40
};

export const textTransitionAnimateOptions = {
  y: 0,
  opacity: 1
};

export const textTransitionTimingFunction = {
  type: 'spring',
  stiffness: 205,
  damping: 32,
  mass: 0.1
};

export const b2bLogoTransition = {
  initial: {
    y: 0,
    scale: 1
  },
  whileHover: {
    y: -4,
    scale: 1.1
  },
  transition: {
    type: 'spring',
    damping: 30,
    stiffness: 300,
    delay: 0
  }
};
