import { Badge, useTheme } from '@inflearn/ds-react';

type Props = {
  children: string;
  icon: React.ReactElement;
};

const CompanyBadge = ({ children, icon }: Props) => {
  const theme = useTheme();
  return (
    <Badge
      leftSection={icon}
      radius={16}
      size="xl"
      color="gray"
      variant="outline"
      styles={{
        inner: {
          color: theme.colors.gray[7]
        },
        root: {
          borderColor: '#EDEDED',
          backgroundColor: 'white'
        }
      }}>
      {children}
    </Badge>
  );
};

export default CompanyBadge;
