import { forEach, includes, isEmpty, map, some, sortBy, split, trim } from 'lodash-es';
import { PROGRAM_TYPE, type SessionSubType } from '../components/infcon-2023/commons/constants';
import { type SpeakerFilterType } from '../components/infcon-2023/session/SpeakerSessionFilterModal';
import {
  type IntermissionTimeRowType,
  type ProgramRowProps,
  type SessionTableRowType,
  type SessionTableTimeRowType,
  type SpeakerRowType
} from '../components/infcon-2023/session/Table';
import { unescape } from '../utils/escape';
import type MyScheduleDto from './MyScheduleDto';
import { type PageData, type PageResponse, type SessionPageData } from './PageDto';

export type SessionPagesResponse = {
  ok: boolean;
  data: PageResponse[];
};

type SessionData = PageData & Required<SessionPageData> & { title: string; profileUrl: string };

class SessionPagesDto {
  readonly sessions: SessionData[];

  constructor(response: SessionPagesResponse) {
    this.sessions = response.data.map(
      ({ id, url, data: session, title, subType, type }): SessionData => ({
        width: session.width,
        tag_id: session.tag_id,
        has_post: session.has_post,
        backgroundColor: session.backgroundColor,
        trackName: session.trackName ?? '',
        affiliation: session.affiliation ?? '',
        speakerName: session.speakerName ?? '',
        sessionLocation: session.sessionLocation ?? '',
        sessionSchedule: session.sessionSchedule ?? '',
        profileUrl: url,
        title: unescape(title),
        id,
        type,
        subType: subType.toLowerCase() as SessionSubType
      })
    );
  }

  getSpeakerSchedules(myScheduleDto?: MyScheduleDto): SessionTableRowType[] {
    const schedules: SessionTableRowType[] = [];

    forEach(SpeakerTableTimes, (session: SpeakerTableType) => {
      if (session.type === 'intermission') {
        const intermissionText = getIntermissionText(session.startedAt);
        schedules.push({
          type: session.type,
          time: `${session.startedAt} - ${session.endedAt}`,
          place: session.startedAt === '17:40' ? '202호' : '전체',
          speaker: intermissionText.speaker,
          content: intermissionText.content
        });
        return;
      }

      if (session.type === 'speaker') {
        const sessionsByStartTime = sortBy(
          this.sessions.filter((sessionData: SessionData) =>
            sessionData.sessionSchedule.includes(session.startedAt)
          ),
          'sessionLocation'
        );
        if (!isEmpty(sessionsByStartTime)) {
          const sessionTableTimeRow: SessionTableTimeRowType = {
            type: session.type,
            startedAt: session.startedAt,
            endedAt: session.endedAt,
            offsetTop: {
              default: '0',
              mobile: '0'
            },
            speakerRows: map(sessionsByStartTime, (row: SessionData) => {
              return {
                place: row.sessionLocation,
                speaker: {
                  name: row.speakerName,
                  company: row.affiliation,
                  profileUrl: row.profileUrl
                },
                sessionName: unescape(row.title),
                trackName: row.trackName,
                key: row.speakerName,
                sessionId: row.id,
                includedMySchedule: myScheduleDto?.hasSessionId(row.id) ?? false
              };
            })
          };

          schedules.push(sessionTableTimeRow);
        }
      }
    });

    return schedules;
  }

  getFilteredSpeakerSchedules(
    filter: SpeakerFilterType,
    myScheduleDto?: MyScheduleDto
  ): SessionTableRowType[] {
    const originalSessions = this.getSpeakerSchedules(myScheduleDto);
    if (originalSessions == null) {
      return [];
    }

    /* 필터가 없을 경우 원래의 값 노출 */
    if (filter.places.length === 0 && filter.categories.length === 0) {
      return originalSessions;
    }

    const filteredSessions: SessionTableRowType[] = [];
    forEach(originalSessions, (session: SessionTableRowType) => {
      /* intermission 삭제 */
      if ('type' in session && session.type === 'intermission') {
        return;
      }

      /* 장소, 카테고리 교집합으로 필터 포함 */
      const hasPlace = (speaker: SpeakerRowType) => {
        const placeNumberString = parseInt(speaker.place).toString();
        return (
          filter.places.length === 0 ||
          some(filter.places, (place) => place.includes(placeNumberString))
        );
      };

      const hasCategory = (speaker: SpeakerRowType) => {
        const trackNames = speaker.trackName
          ? map(split(speaker.trackName.replaceAll('\b', ''), ','), trim)
          : [];

        return (
          filter.categories.length === 0 ||
          some(filter.categories, (category) => includes(trackNames, category))
        );
      };

      const filteredSpeakerRows =
        'speakerRows' in session
          ? session.speakerRows.filter((speaker) => hasPlace(speaker) && hasCategory(speaker))
          : [];

      if (filteredSpeakerRows.length === 0) {
        return;
      }

      filteredSessions.push({
        ...session,
        speakerRows: filteredSpeakerRows
      });
    });

    return filteredSessions;
  }

  getProgramSchedules(): SessionTableRowType[] {
    const schedules: SessionTableRowType[] = [];
    forEach(ProgramTrackNames, (trackName) => {
      const sessionsByTrackName = sortBy(
        this.sessions.filter((sessionData: SessionData) =>
          sessionData.trackName.includes(trackName)
        ),
        'sessionSchedule'
      );

      if (!isEmpty(sessionsByTrackName)) {
        const programRow: ProgramRowProps = {
          programType: trackName, // 핸즈온, 네트워킹, 기업 구분
          rows: map(sessionsByTrackName, (row: SessionData) => {
            return {
              time: row.sessionSchedule,
              place: row.sessionLocation,
              speaker: {
                name: row.speakerName,
                company: row.affiliation,
                profileUrl: row.profileUrl
              },
              sessionName: unescape(row.title),
              key: row.speakerName,
              sessionId: row.id
            };
          })
        };
        schedules.push(programRow);
      }
    });

    return schedules;
  }
}

export type SpeakerTableType = {
  startedAt: string;
  endedAt: string;
  type: 'speaker' | 'intermission';
};

export const SpeakerTableTimes: SpeakerTableType[] = [
  {
    startedAt: '10:00',
    endedAt: '10:30',
    type: 'intermission'
  },
  {
    startedAt: '10:40',
    endedAt: '11:20',
    type: 'speaker'
  },
  {
    startedAt: '11:20',
    endedAt: '11:40',
    type: 'intermission'
  },
  {
    startedAt: '11:40',
    endedAt: '12:20',
    type: 'speaker'
  },
  {
    startedAt: '12:20',
    endedAt: '12:40',
    type: 'intermission'
  },
  {
    startedAt: '12:40',
    endedAt: '13:00',
    type: 'speaker'
  },
  {
    startedAt: '13:00',
    endedAt: '14:00',
    type: 'intermission'
  },
  {
    startedAt: '14:00',
    endedAt: '14:40',
    type: 'speaker'
  },
  {
    startedAt: '14:40',
    endedAt: '15:00',
    type: 'intermission'
  },
  {
    startedAt: '15:00',
    endedAt: '15:20',
    type: 'speaker'
  },
  {
    startedAt: '15:20',
    endedAt: '15:40',
    type: 'intermission'
  },
  {
    startedAt: '15:40',
    endedAt: '16:20',
    type: 'speaker'
  },
  {
    startedAt: '16:20',
    endedAt: '16:40',
    type: 'intermission'
  },
  {
    startedAt: '16:40',
    endedAt: '17:20',
    type: 'speaker'
  },
  {
    startedAt: '17:40',
    endedAt: '18:00',
    type: 'intermission'
  }
];

const ProgramTrackNames = [
  PROGRAM_TYPE.handsOn,
  PROGRAM_TYPE.company,
  PROGRAM_TYPE.networking
] as const;

const getIntermissionText = (
  startedAt: string
): Pick<IntermissionTimeRowType, 'speaker' | 'content'> => {
  if (startedAt === '10:00') {
    return {
      speaker: '이형주, 이동욱',
      content: '인프랩의 미래 - 교육을 넘어 라이프타임 커리어 플랫폼으로'
    };
  }

  if (startedAt === '13:00') {
    return {
      content: 'Lunch Time'
    };
  }

  if (startedAt === '17:40') {
    return {
      speaker: 'Closing',
      content: '인프콘 2023을 마치며'
    };
  }

  return {
    content: 'Break Time'
  };
};

export default SessionPagesDto;
