import React from 'react';
import IntegratedHeader from './IntegratedHeader';
import Header from './Header';
import { Box } from '@inflearn/ds-react';
import MobileHeader from './MobileHeader';

type Props = {
  isRightButtonActive: boolean;
};

const GNB = ({ isRightButtonActive }: Props) => {
  return (
    <>
      <IntegratedHeader />
      <Box
        css={{
          zIndex: 100,
          position: 'sticky',
          top: 0,
          left: 0,
          backgroundColor: isRightButtonActive ? 'rgba(255, 255, 255, 0.8)' : 'white'
        }}>
        <Header isRightButtonActive={isRightButtonActive} />
        <MobileHeader />
      </Box>
    </>
  );
};

export default GNB;
