import { css } from '@emotion/react';
import { Box, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { motion, motionValue, useTransform, type Variants } from 'framer-motion';
import { type RefObject } from 'react';
import useClientHeight from '../../../commons/hooks/useClientHeight';
import { LINE_END, LINE_START } from './constants';

const LineTransition = ({
  scrollY,
  descriptionRef
}: {
  scrollY: number;
  descriptionRef: RefObject<HTMLDivElement> | null;
}) => {
  const parentWidth = descriptionRef?.current?.offsetWidth ?? 0;
  const { clientHeight, sectionVideoTop } = useClientHeight();
  const startAnimation = scrollY >= LINE_START(clientHeight, sectionVideoTop);
  const widthAnimation = useTransform(
    motionValue(scrollY),
    [LINE_START(clientHeight, sectionVideoTop), LINE_END(clientHeight, sectionVideoTop)],
    [0, parentWidth]
  );

  return (
    <motion.div
      layoutScroll
      animate={startAnimation ? 'start' : 'default'}
      variants={lineItemVariants}
      style={{
        width: widthAnimation,
        height: '100%'
      }}
      css={styleLineWrapper}>
      <Box css={styleLine} />
    </motion.div>
  );
};

export const lineItemVariants: Variants = {
  start: {
    display: 'inline-flex',
    opacity: 1
  },
  default: {
    display: 'none',
    opacity: 0
  }
};

const styleLineWrapper = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  flexGrow: 1,
  height: '100%',
  padding: '4px 0',
  [desktopFirstMediaQuery.mobile]: {
    padding: '2px 0'
  }
});

const styleLine = css({
  width: '100%',
  height: '100%',
  borderRadius: 14,
  background: 'linear-gradient(270deg, #66deff 0%, hsl(0, 0%, 100%) 100%)'
});

export default LineTransition;
