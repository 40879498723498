export const INTRODUCE_KEYFRAMES = {
  LENGTH: 2, // 애니메이션 총 길이 (LENGTH * 100vh)
  INTRODUCE_START: 0.5,
  INTRODUCE_END: 1.25
} as const;
export const VIDEO_TRANSITION_KEYFRAMES = {
  LENGTH: 3, // 애니메이션 총 길이 (LENGTH * 100vh)
  INTRO_START: 0,
  INTRO_END: 0.5,
  LINE_START: 0.75,
  LINE_END: 1.5,
  VIDEO_START: 1.5,
  VIDEO_END: 1.75
} as const;

/* IntroduceTransition */
export const INTRODUCE_START = (clientHeight: number) => clientHeight * INTRODUCE_KEYFRAMES.INTRODUCE_START;
export const INTRODUCE_END = (clientHeight: number) => clientHeight * INTRODUCE_KEYFRAMES.INTRODUCE_END;

/* IntroTransition */
export const INTRO_START = (clientHeight: number, sectionVideoTop: number) => sectionVideoTop + clientHeight * VIDEO_TRANSITION_KEYFRAMES.INTRO_START;
export const INTRO_END = (clientHeight: number, sectionVideoTop: number) => sectionVideoTop + clientHeight * VIDEO_TRANSITION_KEYFRAMES.INTRO_END;

/* LineTransition */
export const LINE_START = (clientHeight: number, sectionVideoTop: number) => sectionVideoTop + clientHeight * VIDEO_TRANSITION_KEYFRAMES.LINE_START;
export const LINE_END = (clientHeight: number, sectionVideoTop: number) => sectionVideoTop + clientHeight * VIDEO_TRANSITION_KEYFRAMES.LINE_END;

/* VideoTransition */
export const INTRODUCE_TITLE_WIDTH_PC = '75%';
export const INTRODUCE_TITLE_WIDTH_TB = '80%';
export const INTRODUCE_TITLE_WIDTH_MB = '90%';
export const INTRODUCE_DESCRIPTION_WIDTH_PC = '50%';
export const INTRODUCE_DESCRIPTION_WIDTH_TB = '60%';
export const INTRODUCE_DESCRIPTION_WIDTH_MB = '90%';
export const LINE_DESCRIPTION_START = 0.15;
export const VIDEO_START = (clientHeight: number, sectionVideoTop: number) => sectionVideoTop + clientHeight * VIDEO_TRANSITION_KEYFRAMES.VIDEO_START;
export const VIDEO_END = (clientHeight: number, sectionVideoTop: number) => sectionVideoTop + clientHeight * VIDEO_TRANSITION_KEYFRAMES.VIDEO_END;
