import { css, Global } from '@emotion/react';
import { Box, desktopFirstMediaQuery, ThemeProvider } from '@inflearn/ds-react';
import { LoadingOverlay } from '@mantine/core';
import { type HeadFC } from 'gatsby';
import { useEffect, useRef, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import B2B from '../../components/infcon-2023/main/sections/B2B';
import Hero from '../../components/infcon-2023/main/sections/Hero';
import InteractionSections from '../../components/infcon-2023/main/sections/InteractionSections';
import Map from '../../components/infcon-2023/main/sections/Map';
import SessionFlowList from '../../components/infcon-2023/main/sections/SessionFlowList';
import Share from '../../components/infcon-2023/main/sections/Share';
import SNS from '../../components/infcon-2023/main/sections/SNS';
import Scripts from '../../components/Scripts';
import { queryClient } from '../../layouts/Infcon2023Layout';
import fontFamilies from '../../styles/font';
import global from '../../styles/global';
import reset from '../../styles/reset';
import { GATSBY_SITE_URL } from '../../utils/env';
import Seo from '../../utils/Seo';
import { URLS } from '../../utils/urls';
import useInitNaverMap from '../../utils/useInitNaverMap';

export const SCROLL_WRAPPER_ID = 'scroll-wrapper';

const Infcon2023Page = () => {
  const mainRef = useRef(null);

  useInitNaverMap('koex-map');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider cdnLink={process.env.GATSBY_CDN_URL ?? ''}>
        <Global styles={[reset, global, fontFamilies]} />
        <main ref={mainRef} css={styleMain} id={SCROLL_WRAPPER_ID}>
          <MobileLoading />
          <Hero />
          <InteractionSections />
          <SessionFlowList />
          <B2B />
          <Map />
          <SNS />
          <Share />
        </main>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const MobileLoading = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  }, []);

  return (
    <Box css={styleMobileLoading(visible)}>
      <LoadingOverlay
        loaderProps={{
          size: 'lg',
          color: '#228be6'
        }}
        visible={visible}
        overlayBlur={2}
      />
    </Box>
  );
};

const styleMobileLoading = (visible: boolean) =>
  css({
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'none',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 101,

    [desktopFirstMediaQuery.mobile]: {
      display: visible ? 'flex' : 'none'
    }
  });

const styleMain = css({
  position: 'relative'
});

export default Infcon2023Page;

export const Head: HeadFC = () => {
  return (
    <>
      <Seo
        title="인프콘 2023 - INFCON 2023"
        description="인프런이 만드는 IT인의 축제, 인프콘으로 초대합니다. 인프콘과 함께 배우고 나누고 성장하세요. 다양한 발표 세션, 핸즈온, 네트워킹까지 만나보세요."
        fullUrl={`${GATSBY_SITE_URL}${URLS.INFCON_2023__HOME}`}
      />
      <Scripts />
    </>
  );
};
