import { Box } from '@inflearn/ds-react';
import Header from './Header';
import IntegratedHeader from './IntegratedHeader';
import MobileHeader from './MobileHeader';

const GNB = () => {
  return (
    <>
      <IntegratedHeader />
      <Box
        css={{
          zIndex: 100,
          position: 'sticky',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }}>
        <Header />
        <MobileHeader />
      </Box>
    </>
  );
};

export default GNB;
