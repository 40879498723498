import { useQuery } from 'react-query';
import { type SessionPagesResponse } from '../dtos/SessionPagesDto';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

const useSessionSpeakerPages = (year?: number) => {
  return useQuery(API_URLS.SESSION_LIST(year ?? 2023), async () => {
    try {
      const response = await fetcher.get<SessionPagesResponse>(API_URLS.SESSION_LIST(year ?? 2023));

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useSessionSpeakerPages;
