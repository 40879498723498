import { fromPairs, toPairs } from 'lodash-es';

const POOL = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '`': '&#x60;'
};

const makeEscaper = (mapper: Record<string, string>) => {
  const targets = Object.keys(mapper);

  return (str: string) =>
    str.replace(RegExp(`(?:${targets.join('|')})`, 'g'), (target) => mapper[target]);
};

const unescape = (text: string) =>
  makeEscaper(fromPairs(toPairs(POOL).map(([key, value]) => [value, key])))(text);

const escape = makeEscaper(POOL);

export { makeEscaper, unescape, escape };
