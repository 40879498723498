import { useQuery } from 'react-query';
import { logger, fetcher } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

export type SessionResponse = {
  statusCode: string;
  message: string;
  data?: Session;
};

export type Session = {
  userId: number;
  userEmail: string;
  userName: string;
  userThumbnailUrl: string;
  isCompleteHidden: boolean;
  isBlocked: boolean;
  role: {
    isAdmin: boolean;
    isB2b: boolean;
    isInstructor: boolean;
  };
  enrolmentApproveRequired: boolean;
};

export type SessionRoleDto = {
  code: string;
  name: string;
};

const useV2Session = () => {
  return useQuery(API_URLS.USER_V2_SESSION, async () => {
    try {
      const response = await fetcher.get<SessionResponse>(API_URLS.USER_V2_SESSION);

      if (response.statusCode === 'UNAUTHORIZED') {
        throw new Error();
      }

      if (response.data === undefined) {
        throw new Error();
      }

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useV2Session;
