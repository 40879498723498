import React from 'react';
import { GATSBY_ANT_MAN_URL, GATSBY_CDN_URL } from '../../../utils/env';
import { css } from '@emotion/react';
import { desktopFirstMediaQuery } from '@inflearn/ds-react';

export const COMPANY_INFO = {
  ADDRESS: {
    MAIN: '경기도 성남시 분당구 판교로289번길 20',
    DETAIL: ' 3동 5층'
  },
  EMAIL: 'info@inflearn.com',
  PHONE_NUMBER: '070-4948-1181'
};

const Footer = () => {
  return (
    <div className="footer_bottom" css={footerStyle}>
      <div className="footer_content">
        <div className="footer_bottom_left footer_info">
          <ul className="footer_info_top">
            <li className="is-hidden-mobile">
              <a href="/">
                <img src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-footer-logo.svg`} />
              </a>
            </li>
            <li>
              <a href={`${GATSBY_ANT_MAN_URL}/policy/privacy`} target="_blank" rel="noreferrer">
                개인정보처리방침
              </a>
            </li>
            <li>
              <a
                href={`${GATSBY_ANT_MAN_URL}/policy/terms-of-service`}
                target="_blank"
                rel="noreferrer">
                이용약관
              </a>
            </li>
            <li>
              <a
                href="https://inflearn.channel.io/support-bots/105045"
                target="_blank"
                rel="noreferrer">
                고객센터
              </a>
            </li>
          </ul>
          <div className="footer_info_bottom">
            <div className="is-mobile info_label">
              (주)인프랩 사업자 정보
              <span className="is-mobile info_caret">
                <i className="far fa-angle-up"></i>
                <i className="far fa-angle-down"></i>
              </span>
            </div>
            <div className="info-dropdown">
              <span className="is-hidden-mobile">(주)인프랩 | </span>
              <span>대표자: 이형주 | </span>
              <span>
                사업자번호: 499-81-00612
                <a
                  href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4998100612"
                  target="_blank"
                  rel="noreferrer"
                  css={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                  사업자 정보 확인
                </a>
              </span>
              <br />
              <span>통신판매업: 2018-성남분당B-0062 | </span>
              <span>개인정보보호책임자: 이동욱 | </span>
              <span>
                이메일:{' '}
                <a href="mailto:info@inflearn.com" css={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                  info@inflearn.com
                </a>
              </span>
              <br />
              <span>
                주소: {COMPANY_INFO.ADDRESS.MAIN} {COMPANY_INFO.ADDRESS.DETAIL}
              </span>
            </div>
            ©INFLAB. ALL RIGHTS RESERVED
          </div>
        </div>
      </div>
    </div>
  );
};

const footerStyle = css`
  & {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 32px 20px;
    justify-content: space-between;
    border-top: 1px solid white;
    width: 100%;
    background-color: white;
    border-top: 1px solid #f1f3f5;

    ${desktopFirstMediaQuery.mobile} {
      flex-direction: column;
      align-items: center;
      padding: 24px 20px;
    }

    .footer_content {
      margin: auto;
      width: 100%;
      max-width: 1200px;
    }

    .footer_bottom_left {
      display: flex;
      flex-direction: column;

      ${desktopFirstMediaQuery.mobile} {
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
      }

      .footer_info_top {
        display: flex;
        margin-bottom: 8px;

        li + li {
          &::before {
            display: inline-block;
            content: '|';
            color: white;
            margin: 0 8px;
            font-size: 12px;
          }

          ${desktopFirstMediaQuery.mobile} {
            margin-left: 4px;
            &::before {
              display: none;
            }
          }
        }

        a {
          font-size: 12px;
          color: #495057;
          line-height: 1.5;
          font-weight: bold;
        }

        a.active {
          color: #228be6;
        }
      }

      .footer_info_bottom {
        font-size: 12px;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.5);

        .info_label {
          display: inline-block;
          margin-bottom: 8px;

          ${desktopFirstMediaQuery.mobile} {
            cursor: pointer;
          }

          i.fa-angle-up {
            display: none;
          }

          i.fa-angle-down {
            display: inline;
          }

          &.open {
            i.fa-angle-up {
              display: inline;
            }

            i.fa-angle-down {
              display: none;
            }
          }
          &.is-mobile {
            display: none;

            ${desktopFirstMediaQuery.mobile} {
              display: inline-block;
            }
          }
        }

        .info-dropdown {
          overflow: hidden;
          margin-bottom: 8px;
          word-break: keep-all;
        }
      }
    }

    .footer_bottom_right {
      width: 260px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;

      ${desktopFirstMediaQuery.mobile} {
        margin-left: 0;
      }

      a {
        display: inline-block;
        width: 34px;
        height: 34px;
        border-radius: 100%;
        background: #bdbdbd;
        text-align: center;
        line-height: 35px;

        i {
          display: inline-block;
          font-size: 18px;
          vertical-align: center;
          color: #333;

          &.text {
            font-size: 22px;
            font-weight: bold;
            font-style: normal;
          }
        }
      }
    }
  }
`;

export default Footer;
