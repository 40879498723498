import { throttle } from 'lodash-es';
import { useState, useEffect } from 'react';

const useIsPassedHeroSection = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const onScroll = throttle(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (window.scrollY > window.innerHeight - 64) {
      setIsScrolled(true);

      return;
    }

    setIsScrolled(false);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    isPassedHeroSection: isScrolled
  };
};

export default useIsPassedHeroSection;
