import { css } from '@emotion/react';
import { Box, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import { b2bLogoTransition } from '../../../../../styles/transitions';

export type Company = {
  imageUrl: string;
  name: string;
  pageUrl: string;
};

type Props = {
  badge: React.ReactElement;
  companies: Company[];
  type: string;
  isLast?: boolean;
};

const OtherCompanyGroup = ({ type, badge, companies, isLast = false }: Props) => {
  return (
    <Box component="section" css={styleOtherCompanyGroup(isLast)}>
      <Box mb={20}>{badge}</Box>
      <Box component="ul" css={styleOtherCompanyList}>
        {companies.map((company, index) => (
          <motion.li
            {...b2bLogoTransition}
            key={`${type}-${company.name}-${index}`}
            css={{
              [desktopFirstMediaQuery.mobile]: {
                flexShrink: 1
              }
            }}>
            <a
              target="_blank"
              href={company.pageUrl}
              style={{ width: '100%', height: '100%' }}
              aria-label={`${company.name} 페이지 바로가기`}
              rel="noreferrer">
              <img
                src={company.imageUrl}
                alt={`${company.name} 회사 이미지`}
                css={styleCompanyImage}
              />
            </a>
          </motion.li>
        ))}
      </Box>
    </Box>
  );
};

const styleOtherCompanyList = css({
  display: 'flex',
  columnGap: '40px',
  rowGap: 40,
  maxWidth: 840,
  flexWrap: 'wrap',
  justifyContent: 'center',
  [desktopFirstMediaQuery.tablet]: {
    rowGap: 24
  },
  [desktopFirstMediaQuery.mobile]: {
    maxWidth: 'unset',
    columnGap: 24,
    rowGap: 16
  }
});

const styleOtherCompanyGroup = (isLast: boolean) =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: isLast ? 0 : 80,
    padding: '0 32px',
    [desktopFirstMediaQuery.mobile]: {
      marginBottom: isLast ? 0 : 40,
      padding: 0
    }
  });

const styleCompanyImage = css({
  aspectRatio: '220 / 74',
  width: '180px',
  objectFit: 'contain',
  [desktopFirstMediaQuery.tablet]: {
    width: '140px'
  },
  [desktopFirstMediaQuery.mobile]: {
    width: '90px'
  }
});

export default OtherCompanyGroup;
