import { css } from '@emotion/react';
import { faLeaf, faSeedling, faTree, faTrees } from '@fortawesome/pro-solid-svg-icons';
import { Box, desktopFirstMediaQuery, Icon, Title } from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import {
  textTransitionAnimateOptions,
  textTransitionInitialOptions,
  textTransitionTimingFunction
} from '../../../../../styles/transitions';
import { styleSection } from '../InteractionSections';
import CompanyBadge from './CompanyBadge';
import ForestCompanyGroup from './ForestCompanyGroup';
import OtherCompanyGroup from './OtherCompanyGroup';
import { GATSBY_CDN_URL } from '../../../../../utils/env';

export type Company = {
  imageUrl: string;
  name: string;
  pageUrl: string;
};

const COMPANY_LOGO_URL = (name: string) =>
  `${GATSBY_CDN_URL}/infcon/infcon-2023/company/main-company-${name}.png`;

const B2B = () => {
  return (
    <Box component="section" css={[styleB2BSection, styleSection]}>
      <motion.div
        viewport={{ once: true }}
        transition={{ ...textTransitionTimingFunction }}
        whileInView={textTransitionAnimateOptions}
        initial={textTransitionInitialOptions}>
        <Title order={2} size={60} align="center" mb={80} color="dark.9" css={styleB2BTitle}>
          함께하는 기업들
        </Title>
      </motion.div>
      <motion.div
        viewport={{ once: true }}
        transition={{ ...textTransitionTimingFunction, delay: 0.1 }}
        whileInView={textTransitionAnimateOptions}
        initial={textTransitionInitialOptions}>
        <ForestCompanyGroup
          badge={<CompanyBadge icon={<Icon icon={faTrees} color="#2C9B46" />}>Forest</CompanyBadge>}
          companies={FOREST_COMPANY}
        />
      </motion.div>
      <motion.div
        viewport={{ once: true }}
        transition={{ ...textTransitionTimingFunction, delay: 0.2 }}
        whileInView={textTransitionAnimateOptions}
        initial={textTransitionInitialOptions}>
        <OtherCompanyGroup
          type="Tree"
          badge={<CompanyBadge icon={<Icon icon={faTree} color="#32c354" />}>TREE</CompanyBadge>}
          companies={TREE_COMPANY}
        />
      </motion.div>
      <motion.div
        viewport={{ once: true }}
        transition={{ ...textTransitionTimingFunction, delay: 0.3 }}
        whileInView={textTransitionAnimateOptions}
        initial={textTransitionInitialOptions}>
        <OtherCompanyGroup
          type="Leaf"
          badge={<CompanyBadge icon={<Icon icon={faLeaf} color="#6ed95e" />}>LEAF</CompanyBadge>}
          companies={LEAF_COMPANY}
        />
      </motion.div>
      <motion.div
        viewport={{ once: true }}
        transition={{ ...textTransitionTimingFunction, delay: 0.3 }}
        whileInView={textTransitionAnimateOptions}
        initial={textTransitionInitialOptions}>
        <OtherCompanyGroup
          type="Green & White"
          badge={
            <CompanyBadge icon={<Icon icon={faSeedling} color="#b8e378" />}>
              GREEN & WHITE
            </CompanyBadge>
          }
          companies={GREEN_WHITE_COMPANY}
          isLast
        />
      </motion.div>
    </Box>
  );
};

const styleB2BSection = css({
  padding: '200px 32px',
  backgroundColor: '#f8f8f8',
  [desktopFirstMediaQuery.mobile]: {
    padding: '80px 16px'
  }
});

const styleB2BTitle = css({
  [desktopFirstMediaQuery.tablet]: {
    fontSize: 54
  },
  [desktopFirstMediaQuery.mobile]: {
    marginBottom: 24,
    fontSize: 28
  }
});

const FOREST_COMPANY: Company[] = [
  {
    imageUrl: COMPANY_LOGO_URL('hyundai'),
    name: '현대자동차',
    pageUrl: 'https://talent.hyundai.com/main/main.hc'
  },
  {
    imageUrl: COMPANY_LOGO_URL('inflearn'),
    name: '인프런',
    pageUrl: 'https://www.inflearn.com/'
  }
];

const TREE_COMPANY: Company[] = [
  {
    imageUrl: COMPANY_LOGO_URL('nrise'),
    name: '엔라이즈',
    pageUrl: 'https://career.nrise.net/'
  },
  {
    imageUrl: COMPANY_LOGO_URL('yogiyo'),
    name: '요기요',
    pageUrl: 'https://www.wesang.com/career/apply'
  },
  {
    imageUrl: COMPANY_LOGO_URL('29cm'),
    name: '29cm',
    pageUrl: 'https://www.29cmcareers.co.kr/'
  },
  {
    imageUrl: COMPANY_LOGO_URL('jumpit'),
    name: '점핏',
    pageUrl: 'https://www.jumpit.co.kr/?utm_source=infcon2023&utm_medium=partner&utm_campaign=logo'
  }
];

const LEAF_COMPANY: Company[] = [
  {
    imageUrl: COMPANY_LOGO_URL('herren'),
    name: '헤렌',
    pageUrl: 'https://www.herrencorp.com'
  },
  {
    imageUrl: COMPANY_LOGO_URL('jetbrain'),
    name: '젯브레인',
    pageUrl: 'https://www.jetbrains.com/ko-kr/'
  },
  {
    imageUrl: COMPANY_LOGO_URL('kakaobank'),
    name: '카카오뱅크',
    pageUrl: 'https://kakaobank.com/'
  },
  {
    imageUrl: COMPANY_LOGO_URL('megazoncloud'),
    name: '메가존클라우드',
    pageUrl: 'https://www.megazone.com'
  },
  {
    imageUrl: COMPANY_LOGO_URL('mongodb'),
    name: '몽고DB',
    pageUrl: 'https://www.mongodb.com/ko-kr'
  },
  {
    imageUrl: COMPANY_LOGO_URL('dreamuscompany'),
    name: '드림어스컴퍼니',
    pageUrl: 'https://recruit.dreamuscompany.com/'
  },
  {
    imageUrl: COMPANY_LOGO_URL('kakaopay'),
    name: '카카오페이',
    pageUrl: 'https://www.kakaopay.com'
  },
  {
    imageUrl: COMPANY_LOGO_URL('musinsa'),
    name: '무신사',
    pageUrl: 'https://musinsatech.notion.site/MUSINSA-TECH-db1fdcbe01ee44fb87fcc16f34bd36e5'
  }
];

const GREEN_WHITE_COMPANY: Company[] = [
  {
    imageUrl: COMPANY_LOGO_URL('carrotmarket'),
    name: '당근마켓',
    pageUrl: 'https://about.daangn.com/'
  },
  {
    imageUrl: COMPANY_LOGO_URL('gameduo'),
    name: '게임듀오',
    pageUrl: 'https://gameduo.career.greetinghr.com/'
  },
  {
    imageUrl: COMPANY_LOGO_URL('finda'),
    name: '핀다',
    pageUrl: 'https://www.finda.co.kr/careers'
  }
];

export default B2B;
