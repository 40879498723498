import { GATSBY_SITE_URL } from '../../../utils/env';
import { URLS } from '../../../utils/urls';

export const SHARE_MAIN_URL = `${GATSBY_SITE_URL}${URLS.INFCON_2023__HOME}`; // local 환경에서는 동작 안 함 (로컬용 prefix "/conf"이 존재하지 않기 때문)

export const SESSION_TYPE = {
  speaker: 'speaker', // 발표
  program: 'program' // 프로그램
} as const;

export type SessionType = (typeof SESSION_TYPE)[keyof typeof SESSION_TYPE];

export const PROGRAM_TYPE = {
  handsOn: '핸즈온',
  networking: '네트워킹',
  company: '기업'
} as const;

export type ProgramType = (typeof PROGRAM_TYPE)[keyof typeof PROGRAM_TYPE];

export type SessionSubType = 'session' | 'participation';

export const SESSION_SUB_TYPE: Record<SessionSubType, SessionSubType> = {
  session: 'session',
  participation: 'participation'
};

export const SPEAKER_SESSION_PLACE_TYPE = ['101호', '102호', '103호', '104호', '105호', '202호'];

export const SPEAKER_SESSION_CATEGORY_TYPE = [
  'DevOps',
  '프로그래밍 언어',
  '커리어/성장',
  'Frontend',
  'Backend',
  'SEO/웹접근성',
  '사이드프로젝트',
  '시니어',
  '주니어',
  '협업/생산성',
  '데이터/AI',
  '모바일',
  '테스트',
  '인터렉티브 웹',
  'AWS',
  '아키텍처'
];
