import '@fortawesome/fontawesome-svg-core/styles.css';
import { Global } from '@emotion/react';
import { ThemeProvider } from '@inflearn/ds-react';
import React, { createContext, useContext } from 'react';
import Footer from '../components/infcon-2023/commons/Footer';
import GNB from '../components/infcon-2024/common/GNB';
import { SCROLL_WRAPPER_ID } from '../pages/infcon-2023';
import fontFamilies from '../styles/font';
import global from '../styles/global';
import reset from '../styles/reset';
import { QueryClientProvider, QueryClient } from 'react-query';
import { styleCache } from '../styles/styleCache';
import { AnalyticsProvider } from '@inflearn/analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { Analytics } from 'analytics';
import { HackleProvider } from '../components/infcon-2024/HackleProvider';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  }
});

export const analytics = Analytics({
  app: 'inflearn-monolith',
  plugins: [
    googleTagManager({
      containerId: 'GTM-MVM7ZHX'
    })
  ]
});

const Infcon2024Layout = ({
  children,
  location
}: {
  children: React.ReactNode;
  location: Location;
}) => {
  return (
    <AnalyticsProvider analytics={analytics}>
      <ThemeProvider cdnLink={process.env.GATSBY_CDN_URL ?? ''} emotionCache={styleCache}>
        <LocationProvider location={location}>
          <QueryClientProvider client={queryClient}>
            <HackleProvider>
              <Global styles={[reset, global, fontFamilies]} />
              <GNB css={{ position: 'absolute', top: 0 }} />
              <main id={SCROLL_WRAPPER_ID} css={{ marginTop: '64px' }}>
                {children}
              </main>
              <Footer />
            </HackleProvider>
          </QueryClientProvider>
        </LocationProvider>
      </ThemeProvider>
    </AnalyticsProvider>
  );
};

const locationContext = createContext<Location | null>(null);

const LocationProvider = ({
  location,
  children
}: {
  location: Location;
  children: React.ReactNode;
}) => {
  return <locationContext.Provider value={location}>{children}</locationContext.Provider>;
};

export const useLocation = () => {
  const context = useContext(locationContext);

  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }

  return context;
};

export default Infcon2024Layout;
