import { useEffect, useState } from 'react';

const useMainButtonText = () => {
  const [buttonText, setButtonText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    setButtonText('인프콘 다시보기');
  }, []);

  // TODO: 인프콘 2024때 부활
  // useEffect(() => {
  //   const currentTime = new Date();
  //   const textTargetTime = new Date('2023-07-10T13:00:00+09:00');
  //   const disabledTargetTime = new Date('2023-07-14T00:00:00+09:00');

  //   if (currentTime >= disabledTargetTime) {
  //     setButtonText('신청 마감');
  //     return;
  //   }

  //   if (currentTime >= textTargetTime) {
  //     setButtonText('참가 신청');
  //     return;
  //   }
  //   setButtonText('사전 알림 신청');
  // }, []);

  // TODO: 인프콘 2024때 부활
  // useEffect(() => {
  //   const currentTime = new Date();
  //   const disabledTargetTime = new Date('2023-07-14T00:00:00+09:00');

  //   if (currentTime >= disabledTargetTime) {
  //     setButtonDisabled(true);
  //     return;
  //   }
  //   setButtonDisabled(false);
  // }, []);

  return {
    buttonText,
    buttonDisabled
  };
};

export default useMainButtonText;
