import { css } from '@emotion/react';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Icon,
  type MantineTheme,
  Text,
  useTheme,
  desktopFirstMediaQuery,
  Ellipsis
} from '@inflearn/ds-react';
import { Link } from 'gatsby';
import { URLS } from '../../../../../utils/urls';
import { SESSION_SUB_TYPE, type SessionSubType } from '../../../commons/constants';

type Props = {
  type: string;
  title: string;
  speakerName: string;
  company: string;
  image: string;
  id: number;
  subType: SessionSubType;
};

export const CARD_WIDTH = 320;
export const MOBILE_CARD_WIDTH = 260;

const SessionCard = ({ type, title, speakerName, company, image, id, subType }: Props) => {
  const theme = useTheme();
  return (
    <a href={URLS.INFCON_2023__SESSION_DETAIL(id)}>
      <Box component="article" p={32} css={getSessionCardStyle(theme)}>
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}>
          <Text
            color={subType === SESSION_SUB_TYPE.participation ? 'infgreen.6' : 'blue.6'}
            size="md"
            weight="bold"
            mb={4}
            className="session-card__type">
            #{type}
          </Text>
          <Ellipsis lineClamp={5}>
            <Text color="dark.9" size={24} weight="bold" className="session-card__title">
              {title}
            </Text>
          </Ellipsis>
        </Box>
        <Box css={{ display: 'flex', justifyContent: 'space-between', flexShrink: 0 }}>
          <Box mr={15}>
            <Text color="gray.7" className="session-card__speaker-name" size="xl" weight={500}>
              {speakerName}
            </Text>
            <Text color="gray.5" className="session-card__company" size="sm">
              {company}
            </Text>
          </Box>
          <Box>
            <img
              className="session-card__speaker-image"
              src={image}
              css={{
                width: '50px',
                height: '50px',
                borderRadius: '25px',
                backgroundColor: 'gray',
                objectFit: 'cover'
              }}
            />
            <Box
              css={{
                display: 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50px',
                height: '50px',
                borderRadius: '25px',
                backgroundColor: 'white'
              }}
              className="session-card__link-icon"
              aria-hidden="true">
              <Icon icon={faArrowUpRight} size={24} color="dark" />
            </Box>
          </Box>
        </Box>
      </Box>
    </a>
  );
};

const getSessionCardStyle = (theme: MantineTheme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: `${CARD_WIDTH}px`,
    height: '360px',
    borderRadius: 20,
    backdropFilter: 'blur(40px)',
    boxShadow:
      '0px 0.7961918735236395px 2.3885756205709185px -0.625px rgba(0, 0, 0, 0.05), 0px 2.414506143104518px 7.2435184293135535px -1.25px rgba(0, 0, 0, 0.05), 0px 6.382653521484461px 19.147960564453385px -1.875px rgba(0, 0, 0, 0.05), 0px 20px 60px -2.5px rgba(0, 0, 0, 0.05)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    transitionDuration: '0.3s',
    [desktopFirstMediaQuery.mobile]: {
      width: `${MOBILE_CARD_WIDTH}px`,
      height: '300px',
      '.session-card__type': {
        fontSize: 12
      },
      '.session-card__title': {
        fontSize: 18
      },
      '.session-card__speaker-name': {
        fontSize: 16
      },
      '.session-card__company': {
        fontSize: 12
      }
    },
    ':hover': {
      backgroundColor: theme.colors.dark[9],
      '.session-card__title': {
        color: 'white'
      },
      '.session-card__speaker-name': {
        color: 'white'
      },
      '.session-card__company': {
        color: 'white'
      },
      '.session-card__link-icon': {
        display: 'flex'
      },
      '.session-card__speaker-image': {
        display: 'none'
      }
    }
  });

export default SessionCard;
