import { css } from '@emotion/react';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { Box, desktopFirstMediaQuery, Icon, Text } from '@inflearn/ds-react';
import { motion, type Variants } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { GATSBY_CDN_URL } from '../../../../../utils/env';
import useClientHeight from '../../../commons/hooks/useClientHeight';
import { VIDEO_END, VIDEO_START } from './constants';
import EmbeddedVideo from './EmbeddedVideo';

const VideoTransition = ({ scrollY }: { scrollY: number }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { clientHeight, sectionVideoTop, updateClientHeight } = useClientHeight();
  const [animationState, setAnimationState] = useState<'start' | 'default' | 'exit'>('default');
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpenVideo = () => {
    setOpen(true);
  };

  const handleCloseVideo = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!scrollContainerRef?.current) {
      return;
    }

    if (!scrollContainerRef?.current) {
      return;
    }

    if (scrollY === 0) {
      return;
    }

    if (
      scrollY > VIDEO_START(clientHeight, sectionVideoTop) &&
      scrollY <= VIDEO_END(clientHeight, sectionVideoTop)
    ) {
      setAnimationState('start');
      scrollContainerRef.current.style.position = 'fixed';
      return;
    }

    if (scrollY >= VIDEO_END(clientHeight, sectionVideoTop)) {
      setAnimationState('exit');
      scrollContainerRef.current.style.position = 'sticky';
      handleCloseVideo();
      return;
    }

    updateClientHeight();
    setAnimationState('default');
    scrollContainerRef.current.style.position = 'fixed';
  }, [scrollY]);

  return (
    <>
      <motion.div
        ref={scrollContainerRef}
        css={[
          styleVideo,
          {
            bottom: 0,
            overflowX: 'hidden',
            '::-webkit-scrollbar': {
              display: 'none'
            }
          }
        ]}>
        <motion.video
          css={{
            position: 'absolute',
            filter: 'brightness(75%)'
          }}
          autoPlay
          loop
          muted
          playsInline
          variants={videoVariants}
          animate={animationState}
          poster={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-video-trailer-poster.png`}
          src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-video-trailer.mp4`}
        />
        <motion.div
          variants={videoVariants}
          animate={animationState}
          css={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box
            css={[
              {
                display: 'flex',
                flexFlow: 'column nowrap',
                rowGap: '12px',
                zIndex: 3,
                outline: 'none'
              },
              animationState !== 'default' && {
                pointerEvents: 'all',
                ':hover': {
                  outline: 'none',
                  cursor: 'pointer'
                }
              }
            ]}
            onClick={handleOpenVideo}>
            <Icon icon={faPlay} size={40} color="white" />
            <Text
              size={24}
              color={'white'}
              weight={500}
              css={{
                [desktopFirstMediaQuery.mobile]: { display: 'none' }
              }}>
              예고편 영상 보기
            </Text>
          </Box>
        </motion.div>
      </motion.div>
      <EmbeddedVideo isOpen={isOpen} onClose={handleCloseVideo} />
    </>
  );
};

const videoVariants: Variants = {
  start: {
    opacity: 1,
    width: '100%'
  },
  default: {
    opacity: 0,
    width: '0%'
  },
  exit: {
    opacity: 1,
    width: '100%'
  }
};

const styleVideo = css({
  width: '100%',
  height: 'calc(100vh - 64px)',
  top: '64px',
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover',
  pointerEvents: 'none'
});

export default VideoTransition;
