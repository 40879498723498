import { type Variants } from 'framer-motion';

export const commonSectionVariants: Variants = {
  onscreen: {
    opacity: 1
  },
  offscreen: {
    opacity: 0
  }
};

/* 기본 아이템 (아래에서 위로 나타나기) */

export const commonSectionItemVariants: (variants?: { delay: number }) => Variants = (variants) => ({
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 50,
      ...((variants !== undefined) && { delay: variants.delay })
    }
  },
  offscreen: {
    y: 40,
    opacity: 0
  }
});

export const commonSectionItemVariantsWithoutY: (variants?: { delay: number }) => Variants = (variants) => ({
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 50,
      ...((variants !== undefined) && { delay: variants.delay })
    }
  },
  offscreen: {
    y: 0,
    opacity: 0
  }
});
