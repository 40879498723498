import { createInstance, HackleProvider as HHackleProvider } from '@hackler/react-sdk';
import Cookies from 'js-cookie';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import useV2Session from '../../hooks/useV2Session';
import { GATSBY_HACKLE_CLIENT_KEY } from '../../utils/env';

const hackleClient = createInstance(GATSBY_HACKLE_CLIENT_KEY || '', {
  exposureEventDedupIntervalMillis: 1_000,
  pollingIntervalMillis: 30_000
});

export const HackleProvider = ({ children }: { children: ReactNode }) => {
  const { data: session } = useV2Session();

  useEffect(() => {
    window.hackleClient = hackleClient;
  }, []);

  useEffect(() => {
    if (!session) {
      return;
    }
    const deviceId = Cookies.get('deviceId') ? String(Cookies.get('deviceId')) : undefined;
    const userId = session?.data?.userId ? session.data.userId : undefined;

    userId && hackleClient.setUserId(String(userId));
    deviceId && hackleClient.setDeviceId(deviceId);
    hackleClient.setUserProperty('userid', userId);
    hackleClient.setUserProperty('isadmin', session?.data?.role.isAdmin);
  }, [session]);

  return (
    <HHackleProvider hackleClient={hackleClient} supportSSR>
      {children}
    </HHackleProvider>
  );
};
