import { useEffect } from 'react';
import { ActionIcon, Box, Icon } from '@inflearn/ds-react';
import { faClose } from '@fortawesome/pro-regular-svg-icons';

const VIDEO_URL = 'https://www.youtube.com/embed/wm8bpujQjKM?autoplay=1';

type EmbeddedVideoProps = {
  isOpen: boolean;
  onClose: () => void;
};

const EmbeddedVideo = ({ isOpen, onClose }: EmbeddedVideoProps) => {
  useEffect(() => {
    const Infcon2023HeaderPc = document.getElementById('infcon-2023-header-pc');
    const Infcon2023HeaderMobile = document.getElementById('infcon-2023-header-mobile');

    if (!Infcon2023HeaderPc) {
      return;
    }

    if (!Infcon2023HeaderMobile) {
      return;
    }

    if (isOpen) {
      startEmbeddedVideo();

      /* 영상을 시청하는 동안 스크롤 되지 못하게 처리 */
      document.body.style.overflow = 'hidden';

      /* gnb 영역이 z-index 값으로 인해 가려지지 않아서 임의 처리 */
      Infcon2023HeaderPc.style.opacity = '0';
      Infcon2023HeaderPc.style.pointerEvents = 'none';
      Infcon2023HeaderMobile.style.opacity = '0';
      Infcon2023HeaderMobile.style.pointerEvents = 'none';

      return;
    }

    stopEmbeddedVideo();

    document.body.style.overflow = 'inherit';

    Infcon2023HeaderPc.style.opacity = '1';
    Infcon2023HeaderPc.style.pointerEvents = 'all';
    Infcon2023HeaderMobile.style.opacity = '1';
    Infcon2023HeaderMobile.style.pointerEvents = 'all';
  }, [isOpen]);
  return (
    <Box
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        display: isOpen ? 'flex' : 'none',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        background: 'black',
        overflow: 'hidden',
        zIndex: 32
      }}>
      <Box
        css={{
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'flex-end',
          width: '100%',
          maxWidth: '1200px'
        }}>
        <ActionIcon
          size={48}
          onClick={onClose}
          aria-label="예고편 영상 닫기"
          css={{
            ':hover': {
              backgroundColor: 'transparent'
            }
          }}>
          <Icon icon={faClose} size={24} />
        </ActionIcon>
      </Box>
      <iframe
        id={'infcon-2023-video'}
        width="100%"
        height="auto"
        src={VIDEO_URL}
        title="INFCON 2023 예고편 영상"
        frameBorder="0"
        css={{ aspectRatio: '560 / 315', maxWidth: '1200px' }}
        loading="lazy"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen></iframe>
    </Box>
  );
};

const startEmbeddedVideo = () => {
  const video = document.getElementById('infcon-2023-video');

  if (video instanceof HTMLVideoElement) {
    video.src = VIDEO_URL;
  }
};
const stopEmbeddedVideo = () => {
  const video = document.getElementById('infcon-2023-video');

  if (video instanceof HTMLVideoElement) {
    video.src = '';
  }
};

export default EmbeddedVideo;
