import dayjs from 'dayjs';
import { 이차_디데이, 일차_디데이 } from '../../../../fixtures/dDay';

export function useMainButtonText() {
  const getButtonText = () => {
    const now = dayjs().toDate();
    const 일차디데이 = dayjs(일차_디데이).toDate();
    const 이차디데이 = dayjs(이차_디데이).toDate();

    if (dayjs(now).isBefore(일차디데이)) {
      return '참가신청 알림 설정';
    }

    if (dayjs(now).isBefore(이차디데이)) {
      return '참가신청';
    }

    return '신청 마감';
  };

  const buttonText = getButtonText();

  return {
    buttonText: getButtonText(),
    disabled: buttonText === '신청 마감'
  };
}
