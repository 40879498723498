import { throttle } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

export const useClientHeight = () => {
  const [clientHeight, setClientHeight] = useState<number>(0);
  const [sectionVideoTop, setSectionVideoTop] = useState<number>(0);

  const handleSectionVideo = useCallback(() => {
    const sectionVideo = document.querySelector('#section-video');

    if (sectionVideo instanceof HTMLElement) {
      setSectionVideoTop(sectionVideo.offsetTop);
    }
  }, []);

  const updateClientHeight = () => {
    handleResize();
  };

  const handleResize = throttle(() => {
    setClientHeight(window.innerHeight);
    handleSectionVideo();
  }, 1000);

  useEffect(() => {
    handleResize(); // 초기값 설정을 위해 한 번 실행
    handleSectionVideo();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    clientHeight,
    sectionVideoTop,
    updateClientHeight
  };
};

export default useClientHeight;
