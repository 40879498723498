import { css } from '@emotion/react';
import { Box, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import { b2bLogoTransition } from '../../../../../styles/transitions';

export type Company = {
  imageUrl: string;
  name: string;
  pageUrl: string;
};

type Props = {
  badge: React.ReactElement;
  companies: Company[];
};

const ForestCompanyGroup = ({ badge, companies }: Props) => {
  return (
    <Box component="section" css={styleForestCompanyGroup}>
      <Box mb={20}>{badge}</Box>
      <Box component="ul" css={styleForestCompanyList}>
        {companies.map((company, index) => (
          <motion.li
            {...b2bLogoTransition}
            key={`forest-${company.name}-${index}`}
            css={styleForestCompanyListItem}>
            <a
              href={company.pageUrl}
              target="_blank"
              style={{ width: '100%', height: '100%' }}
              aria-label={`${company.name} 페이지 바로가기`}
              rel="noreferrer">
              <img
                src={company.imageUrl}
                alt={`${company.name} 회사 이미지`}
                css={styleCompanyImage}
              />
            </a>
          </motion.li>
        ))}
      </Box>
    </Box>
  );
};

const styleForestCompanyGroup = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: '80px',
  padding: '0 32px',
  [desktopFirstMediaQuery.mobile]: {
    padding: '0 20px',
    marginBottom: '40px'
  }
});

const styleForestCompanyList = css({
  display: 'flex',
  columnGap: 60,
  rowGap: 20,
  maxWidth: 840,
  flexWrap: 'wrap',
  [desktopFirstMediaQuery.mobile]: {
    maxWidth: 'unset',
    justifyContent: 'center',
    columnGap: 24,
    rowGap: 16,
    flexWrap: 'nowrap'
  }
});

const styleCompanyImage = css({
  aspectRatio: '220 / 74',
  width: '220px',
  objectFit: 'contain',
  [desktopFirstMediaQuery.tablet]: {
    width: '180px'
  },
  [desktopFirstMediaQuery.mobile]: {
    width: '120px'
  }
});

const styleForestCompanyListItem = css({
  [desktopFirstMediaQuery.mobile]: {
    flexShrink: 1,
    width: '100%'
  }
});

export default ForestCompanyGroup;
