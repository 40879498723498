import { type PageContext } from 'gatsby/internal';
import { type ReactNode } from 'react';
import Infcon2023Layout from './Infcon2023Layout';
import Infcon2024Layout from './Infcon2024Layout';

const Layout = ({
  children,
  pageContext,
  location
}: {
  children: ReactNode;
  pageContext: PageContext;
  location: Location;
}) => {
  if (location.pathname.includes('/infcon-2023')) {
    return <Infcon2023Layout location={location}>{children}</Infcon2023Layout>;
  }

  if (location.pathname.includes('/infcon-2024')) {
    return <Infcon2024Layout location={location}>{children}</Infcon2024Layout>;
  }

  return <>{children}</>;
};

export default Layout;
