import 'isomorphic-fetch';
import { isEmpty, isPlainObject } from 'lodash-es';

import { CustomAbortError } from './CustomAbortError';
import type { RequestOptions, RESTDataSource } from './RESTDataSource';

export type IsomorphicFetchParams = {
  endpoint: string;
  timeout?: number;
  credentials?: RequestCredentials;
};

export type RequestParams = {
  path: string;
  method: RequestInit['method'];
  body?: unknown;
  options?: RequestOptions;
};

const DEFAULT_REQUEST_TIME_OUT = 5 * 1_000;
enum HTTPStatus {
  NOT_MODIFIED = 304
}

/**
 * 각 패키지에서 필요한 형태로 확장해서 사용할 수 있는 Fetcher 모듈입니다.
 * 추상 클래스이기 때문에 반드시 확장해서 사용해야 합니다.
 * @alpha
 *
 * #### Life cycle
 * - `beforeRequest` -> `fetch` -> `afterRequest`라는 life cycle로 동작합니다.
 * - `performError`라는 method로 error를 handling할 수 있습니다.
 * - `beforeRequest`, `afterRequest`, `performError`는 기본적으로는 별다른 동작을 하지 않으며, override 했을 때 유용하게 사용할 수 있습니다.
 *
 * #### Cache
 * - 별도의 cache layer가 존재하지 않는 이유는 request query에서도 기본적으로 caching을 하기 때문입니다. 필요에 따라 나중에 `LRU cache`를 추가할 수도 있습니다.
 *
 * @example
 * ```ts
 * class Fetcher extends IsomorphicFetch {
 *   override beforeRequest(params: RequestParams) {!
 *     logger.info(`[${params.method}]: ${params.path}`)
 *
 *     return params
 *   }
 *
 *   override afterRequest(status: Response['status'], requestInfo: RequestParams, data: unknown) {
 *     return humps.camelizeKeys(data)
 *   }
 *
 *   override performError(e: unknown) {
 *     if (e instanceof NetworkError) {
 *       logger.error(`message: ${e.message}`)
 *     }
 *
 *     if (e instanceof AccountAPIError && e.status === 'UNAUTHORIZED') {
 *       logger.info(e.message)
 *     }
 *
 *     logger.error(e)
 *   }
 * }
 * ```
 */
export abstract class IsomorphicFetch implements RESTDataSource {
  private readonly BASE_URL: string;
  private readonly timeout: number;
  private readonly defaultCredentialsStrategy: RequestCredentials;
  constructor({ endpoint, timeout, credentials }: IsomorphicFetchParams) {
    this.BASE_URL = endpoint;
    this.timeout = timeout ?? DEFAULT_REQUEST_TIME_OUT;
    this.defaultCredentialsStrategy = credentials ?? 'same-origin';
  }

  async get<Response = unknown>(path: string, options?: RequestOptions) {
    return await this.requestPipe<Response>({ path, method: 'GET', options });
  }

  async post<Response = unknown, Body = unknown>(
    path: string,
    body: Body,
    options?: RequestOptions
  ) {
    return await this.requestPipe<Response>({
      path,
      method: 'POST',
      body,
      options
    });
  }

  async patch<Response = unknown, Body = unknown>(
    path: string,
    body: Body,
    options?: RequestOptions
  ) {
    return await this.requestPipe<Response>({
      path,
      method: 'PATCH',
      body,
      options
    });
  }

  async put<Response = unknown, Body = unknown>(
    path: string,
    body: Body,
    options?: RequestOptions
  ) {
    return await this.requestPipe<Response>({
      path,
      method: 'PUT',
      body,
      options
    });
  }

  async delete<Response = unknown, Body = unknown>(
    path: string,
    body?: Body,
    options?: RequestOptions
  ) {
    return await this.requestPipe<Response>({
      path,
      method: 'DELETE',
      body,
      options
    });
  }

  /**
   * @virtual
   * *`beforeRequest`* -> request -> afterRequest
   *
   * - API request를 요청하기 전에 호출됩니다.
   * - `RequestParams` type을 **반드시 return**해야 합니다.
   */
  protected beforeRequest(params: RequestParams): RequestParams {
    return params;
  }

  /**
   * @virtual
   * beforeRequest -> request -> *`afterRequest`*
   *
   * - API 요청이 정상적으로 도달한 후 호출됩니다.
   * - 예를들어, fetch의 경우 `result.ok` === `true`, `result.json()`의 실행이 큰 문제가 없었을 때 입니다.
   * - Parameter로 받은 data를 **반드시 return**해야 합니다.
   */
  protected afterRequest<T>(
    status: Response['status'],
    responseHeaders: Response['headers'],
    requestInfo: RequestParams,
    data: T
  ): T {
    return data;
  }

  /**
   * @virtual
   *
   * - 예외가 발생한 경우 호출됩니다.
   * - 예를들어, fetch의 경우 `result.ok` === `false`일때나 catch 문 내부에서 호출됩니다.
   */
  // eslint-disable-next-line
  protected performError(e: unknown): void {}

  private async requestPipe<Response = unknown>(params: RequestParams) {
    const requestParams = this.beforeRequest(params);

    try {
      const response = await this.request(requestParams);

      const data = (await response.json()) as Response;

      return this.afterRequest(response.status, response.headers, requestParams, data);
    } catch (e) {
      const error = () => {
        if (e instanceof DOMException && e.name === 'AbortError') {
          return new CustomAbortError(e.message, e.name, requestParams.path);
        }

        return e;
      };

      this.performError(error());

      throw error();
    }
  }

  private async request({ path, method, body, options }: RequestParams) {
    const url = `${options?.endpoint ? options?.endpoint : this.BASE_URL}${path}`;
    const requestOptions = this.makeRequestInitOptions(body, options);

    const result = await fetch(url, {
      method,
      ...requestOptions
    });

    if (result.ok || result.status === HTTPStatus.NOT_MODIFIED) {
      return result;
    }

    /**
     * TODO: 에러를 어떻게 발생시키면 좋을까?
     */
    throw new Error(`result is not ok: [${method}] ${path}`);
  }

  private abortSignal() {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
      clearTimeout(timeoutId);
    }, this.timeout);

    timeoutId?.unref?.();

    return controller.signal;
  }

  private makeRequestInitOptions(data: unknown, options?: RequestOptions) {
    const isFormData = data instanceof FormData;
    const headers = {
      headers: Object.assign(
        {
          ...(isFormData ? {} : { 'Content-Type': 'application/json' })
        },
        options?.headers ?? {}
      )
    };

    const isValidBody = this.validBody(data);
    const parsedData = isFormData ? data : JSON.stringify(data);
    const body = isValidBody ? { body: parsedData } : {};

    const requestInitOptions: RequestInit = {
      cache: options?.cache ?? 'default',
      signal: this.abortSignal(),
      credentials: options?.credentials ?? this.defaultCredentialsStrategy
    };

    return Object.assign(headers, body, requestInitOptions);
  }

  private validBody(body: unknown) {
    return body instanceof FormData || (isPlainObject(body) && !isEmpty(body));
  }
}
