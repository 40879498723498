export class CustomAbortError extends DOMException {
  constructor(
    message: string,
    name: string,
    readonly api: string
  ) {
    super(message, name);
    this.api = api;
  }
}
