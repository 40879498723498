import { css } from '@emotion/react';
import { Box, desktopFirstMediaQuery } from '@inflearn/ds-react';
import React from 'react';
import IntroduceSection from './IntroduceSection';
import VideoSection from './VideoSection';

const InteractionSections = () => {
  return (
    <Box
      css={{
        position: 'relative',
        zIndex: 4 /* 임베드 영상이 아래 섹션에 가려지지 않게 하기 위함 */,
        backgroundColor: '#F6FAFE'
      }}>
      <IntroduceSection />
      <VideoSection />
    </Box>
  );
};

export const styleSection = css({
  position: 'relative',
  zIndex: 2,
  padding: '200px 32px',
  [desktopFirstMediaQuery.mobile]: {
    padding: '80px 16px'
  }
});

export default InteractionSections;
