import { Global } from '@emotion/react';
import { ThemeProvider } from '@inflearn/ds-react';
import React, { createContext, useContext } from 'react';
import Footer from '../components/infcon-2023/commons/Footer';
import GNB from '../components/infcon-2023/commons/GNB';
import { SCROLL_WRAPPER_ID } from '../pages/infcon-2023';
import fontFamilies from '../styles/font';
import global from '../styles/global';
import reset from '../styles/reset';
import { QueryClientProvider, QueryClient } from 'react-query';
import { styleCache } from '../styles/styleCache';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  }
});

const Infcon2023Layout = ({
  children,
  location
}: {
  children: React.ReactNode;
  location: Location;
}) => {
  return (
    <ThemeProvider cdnLink={process.env.GATSBY_CDN_URL ?? ''} emotionCache={styleCache}>
      <LocationProvider location={location}>
        <QueryClientProvider client={queryClient}>
          <Global styles={[reset, global, fontFamilies]} />
          <GNB isRightButtonActive={true} css={{ position: 'absolute', top: 0 }} />
          <main id={SCROLL_WRAPPER_ID} css={{ marginTop: '64px' }}>
            {children}
          </main>
          <Footer />
        </QueryClientProvider>
      </LocationProvider>
    </ThemeProvider>
  );
};

const locationContext = createContext<Location | null>(null);

const LocationProvider = ({
  location,
  children
}: {
  location: Location;
  children: React.ReactNode;
}) => {
  return <locationContext.Provider value={location}>{children}</locationContext.Provider>;
};

export const useLocation = () => {
  return useContext(locationContext);
};

export default Infcon2023Layout;
