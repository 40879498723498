export const GATSBY_ENV = process.env.GATSBY_ENV ?? '';
export const GATSBY_SENTRY_DSN = process.env.GATSBY_SENTRY_DSN ?? '';
export const GATSBY_CDN_URL = process.env.GATSBY_CDN_URL ?? '';
export const GATSBY_API_URL = process.env.GATSBY_API_URL ?? '';
export const GATSBY_SITE_URL = process.env.GATSBY_SITE_URL ?? '';
export const GATSBY_ANT_MAN_URL = process.env.GATSBY_ANT_MAN_URL ?? '';
export const GATSBY_ANT_MAN_API_URL = process.env.GATSBY_ANT_MAN_API_URL ?? '';
export const GATSBY_MIXPANEL_TOKEN = process.env.GATSBY_MIXPANEL_TOKEN ?? '';
export const GATSBY_RALLIT_DOMAIN = process.env.GATSBY_RALLIT_DOMAIN ?? '';
export const GATSBY_ACCOUNT_API_URL = process.env.GATSBY_ACCOUNT_API_URL ?? '';
export const GATSBY_HACKLE_CLIENT_KEY = process.env.GATSBY_HACKLE_CLIENT_KEY ?? '';
export const GATSBY_HACKLE_SERVER_KEY = process.env.GATSBY_HACKLE_SERVER_KEY ?? '';
