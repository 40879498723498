import { Logger } from '@inflearn/logger';
import { GATSBY_ACCOUNT_API_URL, GATSBY_ANT_MAN_API_URL } from './env';
import { IsomorphicFetch } from './IsomorphicFetch';

const REQUEST_TIME_OUT = 10 * 1000;

export const logger = new Logger();

class Fetcher extends IsomorphicFetch {
  endpoint: string;
  credentials: RequestCredentials;

  constructor(options: { endpoint: string; timeout: number; credentials?: RequestCredentials }) {
    super(options);
    this.endpoint = options.endpoint;
    this.credentials = options.credentials ?? 'include';
  }
}

export const fetcher = new Fetcher({
  endpoint: GATSBY_ANT_MAN_API_URL ?? '',
  timeout: REQUEST_TIME_OUT,
  credentials: 'include'
});

export const accountFetcher = new Fetcher({
  endpoint: GATSBY_ACCOUNT_API_URL ?? '',
  timeout: REQUEST_TIME_OUT,
  credentials: 'include'
});
