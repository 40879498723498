import { GATSBY_CDN_URL } from './env';

/**
 * 쌍따옴표가 포함되어 있는 문자열을 렌더링하게 됐을 때, SEO 태그 내부에서 문자열이 끊기는 이슈 방지
 * @param {string} text
 * @returns {string}
 */
const sanitizeText = (text: string) => {
  return text.replace(/"/g, "'");
};

// 20200205 새로운 SEO function
const MAIN_IMG_BANNER = `${GATSBY_CDN_URL}/infcon/infcon-2023/infcon-teaser-og.png`;

type Props = {
  title: string;
  description: string;
  robots?: boolean;
  image?: string;
  fullUrl: string;
};

const ROBOTS = true;

const Seo = ({
  title,
  description,
  robots = ROBOTS,
  image = MAIN_IMG_BANNER,
  fullUrl //
}: Props) => {
  const description152 = (description: string) => {
    if (description.length <= 152) {
      return description;
    }
    return description.substr(0, 152) + '...';
  };

  return (
    <>
      <title>{sanitizeText(title)}</title>
      <meta name="description" content={sanitizeText(description152(description))} />

      <meta name="robots" content={robots ? 'index' : 'noindex'} />

      <link rel="canonical" href={fullUrl} />
      <link
        rel="favicon"
        href={`${GATSBY_CDN_URL}/infcon/infcon-2023/favicon.png`}
        type="image/png"
      />
      <link rel="icon" href={`${GATSBY_CDN_URL}/infcon/infcon-2023/favicon.png`} type="image/png" />

      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={sanitizeText(title)} />
      <meta property="og:description" content={sanitizeText(description152(description))} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:site_name" content="인프콘" />
      <meta property="og:locale" content="ko-KR" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={sanitizeText(title)} />
      <meta name="twitter:description" content={sanitizeText(description152(description))} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:site" content="@inflearn" />
      <meta name="twitter:creator" content="@inflearn" />

      <meta itemProp="name" content={sanitizeText(title)} />
      <meta itemProp="description" content={sanitizeText(description152(description))} />
      <meta itemProp="image" content={image} />
    </>
  );
};

export const getSeoForHelmet = ({
  title,
  description,
  robots = ROBOTS,
  image = MAIN_IMG_BANNER,
  fullUrl
}: Props) => {
  const description152 = (description: string) => {
    if (description.length <= 152) {
      return description;
    }
    return description.substr(0, 152) + '...';
  };

  return [
    <title key="title">{sanitizeText(title)}</title>,
    <meta
      key="description"
      name="description"
      content={sanitizeText(description152(description))}
    />,

    <meta key="robots" name="robots" content={robots ? 'index' : 'noindex'} />,

    <link key="canonical" rel="canonical" href={fullUrl} />,
    <link
      key="favicon"
      rel="favicon"
      href={`${GATSBY_CDN_URL}/infcon/infcon-2023/favicon.png`}
      type="image/png"
    />,
    <link
      key="icon"
      rel="icon"
      href={`${GATSBY_CDN_URL}/infcon/infcon-2023/favicon.png`}
      type="image/png"
    />,

    <meta key="og:url" property="og:url" content={fullUrl} />,
    <meta key="og:type" property="og:type" content="website" />,
    <meta key="og:title" property="og:title" content={sanitizeText(title)} />,
    <meta
      key="og:description"
      property="og:description"
      content={sanitizeText(description152(description))}
    />,
    <meta key="og:image" property="og:image" content={image} />,
    <meta key="og:image" property="og:image:secure_url" content={image} />,
    <meta key="og:site_name" property="og:site_name" content="인프콘" />,
    <meta key="og:locale" property="og:locale" content="ko-KR" />,

    <meta key="twitter:card" name="twitter:card" content="summary" />,
    <meta key="twitter:title" name="twitter:title" content={sanitizeText(title)} />,
    <meta
      key="twitter:description"
      name="twitter:description"
      content={sanitizeText(description152(description))}
    />,
    <meta key="twitter:image" name="twitter:image" content={image} />,
    <meta key="twitter:url" name="twitter:url" content={fullUrl} />,
    <meta key="twitter:site" name="twitter:site" content="@inflearn" />,
    <meta key="twitter:creator" name="twitter:creator" content="@inflearn" />,

    <meta key="name" itemProp="name" content={sanitizeText(title)} />,
    <meta
      key="description"
      itemProp="description"
      content={sanitizeText(description152(description))}
    />,
    <meta key="image" itemProp="image" content={image} />
  ];
};

export default Seo;
